/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~weather-icons/css/weather-icons.css';
@import '~weather-icons/css/weather-icons-wind.css';

@import '~loaders.css/loaders.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== NgSelect
@import "~@ng-select/ng-select/themes/default.theme.css";
//== BsDateRangePicker
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

//== Swal confirm buttons
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $primary !important;

  &:focus {
    box-shadow: 0 0 0 3px rgba($primary, 0.25) !important;
  }
}